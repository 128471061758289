import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P5 = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p5.jpg" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">报警室外装置
                    </span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <StaticImage src="../../data/product/p5-1.png" />
                    <StaticImage src="../../data/product/p5-2.png" />
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P5





